.profile-input {
    padding: 1rem;
    margin: 1rem;
    height: 2rem;
    border-style: none;
    border: 1px grey solid;
    width: 100%;
}

.password-alert {
    color: brown;
    font-size: 0.7rem;
}
