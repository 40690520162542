select {
    width: 200px; /* Set the width of the dropdown */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 2px;
    background-color: #f5f5f5;
    color: #333;
    font-size: 0.8rem;
    cursor: pointer;
}

/* Style the options */
select option {
    background-color: #f5f5f5;
    color: #333;
    font-size: 16px;
}

/* Style the hover effect for options 
  select option:hover {
    background-color: #ddd;
  }*/
