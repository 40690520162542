.review-section {
    margin: 1rem;
}

.rating-label {
    margin-right: 0.5rem;
    width: 100px;
}
.rating {
    margin-bottom: 1rem;
    display: flex;
}
