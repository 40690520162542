.radio-box {
    position: relative;
}

input[type='radio'] {
    width: 20px;
    height: 20px;
    opacity: 0; /* Hide the original radio button */
    position: absolute;
    z-index: 2;
    cursor: pointer;
}

.tick-mark {
    width: 20px;
    height: 20px;
    border: 2px solid gray;
    display: block;
    border-radius: 50%;
    position: relative;
    cursor: pointer; /* Make the span clickable */
    background-color: transparent; /* Initial background color */
}

input:checked ~ .tick-mark {
    border: 2px solid rgb(15, 139, 223);
    background-color: rgb(15, 139, 223); /* Background color when checked */
}

input:checked ~ .tick-mark:after {
    width: 8px;
    height: 8px;
    background-color: white; /* Background color for inner circle when checked */
    display: block;
    border-radius: 50%;
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
}
