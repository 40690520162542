/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none; /* Initially, the overlay should not capture clicks */
    transition:
        background-color 0.3s,
        opacity 0.3s,
        pointer-events 0s 0.3s; /* Add a transition effect */
    z-index: 40;
}

/* When the modal is open, adjust the styles for the fade-in effect */
.modal-overlay.open {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
    pointer-events: auto; /* Enable pointer events to capture clicks */
}

.modal-content-login {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    transform: scale(1.2); /* Start with a larger scale */
    opacity: 0; /* Start with opacity set to 0 */
    transition:
        transform 0.3s,
        opacity 0.3s; /* Add a transition effect for scaling and opacity */
}

.modal-content-password {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    transform: scale(1.2); /* Start with a larger scale */
    opacity: 0; /* Start with opacity set to 0 */
    transition:
        transform 0.3s,
        opacity 0.3s; /* Add a transition effect for scaling and opacity */
}

.modal-content-signup {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    transform: scale(1.2); /* Start with a larger scale */
    opacity: 0; /* Start with opacity set to 0 */
    transition:
        transform 0.3s,
        opacity 0.3s; /* Add a transition effect for scaling and opacity */
}

/* When the modal is open, adjust the styles for the pop-up effect */
.modal-overlay.open .modal-content-password {
    transform: scale(1);
    opacity: 1;
    z-index: 999;
}

.modal-overlay.open .modal-content-signup {
    transform: scale(1);
    opacity: 1;
    z-index: 999;
}

.modal-overlay.open .modal-content-login {
    transform: scale(1);
    opacity: 1;
}

.modal-content-login {
    z-index: 99 !important;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 16px;
    color: #333;
    padding: 0;
}

.modal-overlay.open a {
    cursor: pointer;
}

.input-area {
    display: flex;
    flex-direction: column;
}

.input-two-group-horizontal {
    display: flex;
    flex-direction: row;
}

.input-item {
    height: 100%;
}

@media (max-width: 640px) {
}

@media (max-width: 768px) {
}

@media (max-width: 1024px) {
}

@media (max-width: 1280px) {
}

@media (max-width: 1536px) {
}
